<template>
  <div
    class="friend-timeline-list-container"
    :class="{
      forbidden,
      '-theme-group': renderType === 'group',
    }"
  >
    <div
      class="ftl-top-info-container"
      v-if="
        (timelineid === undefined && $route.query.type !== 'all') || (groupID > 0 && timelineid > 0)
      "
    >
      <div class="info__container">
        <div class="info__item" v-if="!(groupID > 0)">排行</div>
        <div class="info__item">{{ renderType === "group" ? "小组" : "亲友" }}</div>
        <div class="info__item" v-if="!(groupID > 0 && timelineid > 0)">
          {{ renderType === "group" ? "时光" : "关联时光" }}
        </div>
      </div>
      <div class="info__container --val">
        <div class="info__item" v-if="!(groupID > 0)">
          {{ $route.query.type ? "自己" : fpInfo("position") }}
        </div>
        <div
          class="info__item"
          @click="goGroup"
          :class="{
            blue: groupID > 0,
          }"
        >
          {{
            $route.query.type
              ? user
                ? user.nickname
                : ""
              : groupID > 0
              ? groupInfo
                ? groupInfo.title
                : ""
              : fpInfo("nickname")
          }}
        </div>
        <div class="info__item" v-if="!(groupID > 0 && timelineid > 0)">
          <strong>{{ totalSize }}</strong>
        </div>
      </div>
    </div>
    <van-list v-model="requesting" :finished="finished" finished-text="" @load="geData">
      <timeline-item
        class="friend-timeline-wrapper"
        :wrapperData="lists"
        :is-stranger="isStranger"
        :renderType="renderType"
        :groupMangers="groupMangers"
      ></timeline-item>
    </van-list>
    <slogan :text="sloganText"></slogan>
    <!-- 添加按钮 -->
    <div class="topic-create-bt" v-if="friendUid || groupID" @click="onCreateTimeline">
      <svg-icon
        icon-class="icon-plus-red"
        :color="renderType === 'group' ? '#027711' : '#C81D25'"
      ></svg-icon>
    </div>
    <!-- 时间筛选按钮 -->
    <div class="timeline-filter" v-if="pickerShow" @click="onDatePicker">
      <div class="inner">
        <p class="text">{{ pickText }}</p>
        <svg-icon class="icon" icon-class="icon-arrow-up" color="#fff"></svg-icon>
      </div>
    </div>
    <!-- 底部导航 -->
    <div class="bottom-menu-container" ref="xxx">
      <!-- <div class="menu__item" @click="goBack"><strong>返回</strong></div> -->
      <share-bottom></share-bottom>
    </div>
    <!-- 时间筛选弹窗 -->
    <van-popup v-model="showDatePick" position="bottom">
      <van-picker
        class="date-picker"
        ref="datePicker"
        :loading="pickOptionsLoading"
        :columns="ccolumns"
        @change="onPickerChange"
        @confirm="onPickerConfirm"
        @cancel="onPickerCancel"
        show-toolbar
      ></van-picker>
    </van-popup>
  </div>
</template>

<script>
import forbiddenMixin from '@/mixins/forbidden';
import { mapActions, mapMutations, mapState } from 'vuex';
import wxtool from '@/utils/wx-tool';
import * as dict from '@/utils/dict';
import slogan from '../common/_slogan.vue';
import timelineItem from '../timeline/_index.vue';
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'friend-timeline-list',
  props: ['friendUid', 'timelineid', 'groupID'],
  components: {
    timelineItem,
    slogan,
    shareBottom,
  },
  mixins: [forbiddenMixin],
  data() {
    // const currentYear = (new Date()).getFullYear();
    const _month = Array.from(Array(12).keys()).map((i) => ({
      text: `${`${i + 1}`.padStart(2, 0)}月`,
      val: i + 1,
      disabled: false,
    }));
    return {
      requesting: false,
      finished: false,
      lists: [],
      page: 1,
      totalPage: 1,
      totalSize: 0,
      isStranger: true,
      groupInfo: null,
      showDatePick: false,
      pickText: '全部',
      columns: [
        {
          text: '全部',
          val: 0,
          children: [
            {
              text: '',
              val: 0,
            },
          ],
        },
      ],
      ccolumns: [
        {
          values: [
            {
              text: '全部',
              val: 0,
            },
          ],
        },
        {
          values: [
            {
              text: '全年',
              disabled: false,
              val: 0,
            },
            ..._month,
          ],
        },
      ],
      pickConfig: {
        year: 0,
        month: 0,
      },
      pickOptionsLoading: false,
      pickDefaultIndex: 0,
      pickYearWhiteMonth: {},
    };
  },
  computed: {
    ...mapState(['user', 'myGroups']),
    renderType() {
      if (
        this.friendUid === undefined
        && this.groupID === undefined
        && this.timelineid === undefined
      ) {
        return 'user';
      }
      if (this.friendUid > 0) {
        return 'friend';
      }
      if (this.groupID > 0) {
        return 'group';
      }
      return 'timeline-detail';
    },
    pickerShow() {
      return (
        ['user', 'friend'].includes(this.renderType)
        || (this.renderType === 'group' && this.timelineid === undefined)
      );
    },
    requestURI() {
      const that = this;
      switch (that.renderType) {
        case 'user':
          return `api/user/timeline/post?type=${that.$route.query.type}`;
        case 'friend':
          return `api/user/friend/timelines/${that.friendUid}`;
        case 'group':
          return that.timelineid > 0
            ? `api/group/${that.groupID}/timeline/post/${that.timelineid}`
            : `api/group/${that.groupID}/timeline/list`;
        case 'timeline-detail':
          return `api/user/timeline/detail/${that.timelineid}`;
        default:
          return '';
      }
    },
    sloganText() {
      return this.renderType === 'group' ? '✖ 同伴的标志 ✖' : '没有人是一座孤岛';
    },
    groupMangers() {
      if (this.groupID > 0 && this.groupInfo) {
        const admins = this.groupInfo.admins.map((member) => member.uid);
        return [...admins, this.groupInfo.author.uid];
      }
      return [];
    },
    triggerWxShare() {
      if (this.groupID) {
        return !!(this.user && this.groupInfo);
      }
      return !!(this.user && this.lists.length > 0);
    },
  },
  watch: {
    $route: {
      handler() {
        this.pageInit();
      },
      immediate: true,
    },
    triggerWxShare(val) {
      if (val) {
        this.wxShare();
      }
    },
  },
  mounted() {
    const that = this;
    that.$root.$on('afterPostDeleted', () => {
      console.log('after post deleted');
    });
    if (that.groupID > 0) {
      that.getGroup(that.groupID).then(() => {
        that.groupInfo = that.myGroups.get(Number(that.groupID));
        that.totalSize = that.groupInfo.totalTimelineCount;
        // console.log('group timeline');
        // that.wxShare();
      });
    }
    if (this.triggerWxShare) {
      this.wxShare();
    }
    // 获取时间区间
    if (this.pickerShow) {
      that.getTimeRange();
    }
  },
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM']),
    ...mapActions(['getGroup']),
    pageInit() {
      this.requesting = false;
      this.finished = false;
      this.lists = [];
      this.page = 1;
      this.totalPage = 1;
    },
    geData() {
      if (this.timelineid !== undefined) {
        // 时光详情
        this.getDetail();
      } else {
        // 三种时光列表 好友 | 小组 | 自己
        this.getTimeline();
      }
    },
    getTimeline() {
      const that = this;

      const url = that.requestURI;

      const query = {
        page: that.page,
      };

      if (!(+that.pickConfig.year === 0 && +that.pickConfig.month === 0)) {
        query.year = that.pickConfig.year;
        query.month = that.pickConfig.month;
      }

      that.$request
        .get(url, query)
        .then((res) => {
          const lists = res.data.list;
          const meta = res.data._meta;
          that.totalPage = meta.pageCount;
          that.totalSize = meta.totalCount;
          that.lists.push(...lists);
          if (that.page >= that.totalPage) {
            that.finished = true;
          }
          that.page += 1;
          that.isStranger = false;
        })
        .catch((err) => {
          const errurl = err.data ? `/friend/${err.data.author_uid}` : '/';
          if (err.status === 403 || err.status === 500) {
            this.notifyForbidden('timeline', err.message, '查看发布人', errurl);
          }
          if (err.status === 404) {
            this.notifyNotFound('该时光已消失', errurl);
          }
          that.finished = true;
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    getDetail() {
      const that = this;
      that.$request
        .get(that.requestURI)
        .then((res) => {
          const { data } = res;
          that.lists.push(data);
          that.isStranger = false;
          that.finished = true;
          // console.log('not group timeline');
          // that.wxShare();
        })
        .catch((err) => {
          const url = err.data ? `/friend/${err.data.author_uid}` : '/';
          if (err.status === 403 || err.status === 500) {
            this.notifyForbidden('timeline', err.message, '查看发布人', url);
          }
          if (err.status === 404) {
            this.notifyNotFound('该时光已消失', url);
          }
          that.finished = true;
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    onCreateTimeline() {
      let url;
      if (this.groupID > 0) {
        url = `/timeline/edit?groupID=${this.groupID}`;
      } else if (this.friendUid > 0) {
        url = `/timeline/edit?friend=${this.friendUid}`;
      } else {
        url = '/timeline/edit';
      }
      this.$router.push({
        path: url,
      });
    },
    fpInfo(key) {
      const info = this.$store.getters.friendPosition(this.friendUid);
      return info ? info[key] : '/';
    },
    wxShare() {
      const share = {
        title: '',
        desc: '',
        link: '',
        imgUrl: '',
      };
      share.title = `${this.user?.nickname} 和你分享了${this.groupInfo ? '小组内容' : '时光'}`;
      share.desc = this.groupInfo
        ? `来自小组：${this.groupInfo.title}`
        : '建设你 建设我 建设我们共同的时光';
      if (this.groupInfo) {
        share.link = this.timelineid
          ? `${process.env.VUE_APP_BASE}group/${this.groupID}/timeline/${this.timelineid}`
          : `${process.env.VUE_APP_BASE}group/${this.groupID}/timelines`;
      } else {
        share.link = `${process.env.VUE_APP_BASE}timeline/${this.timelineid}`;
      }

      if (this.groupInfo) {
        share.imgUrl = this.$root.$options.filters.parseImage(this.groupInfo.cover);
      } else {
        share.imgUrl = this.lists[0].author
          ? this.lists[0].author.headimgurl
          : dict.logoutDefault.shareimg;
      }

      // share.imgUrl = this.groupInfo
      //   ? this.$root.$options.filters.parseImage(this.groupInfo.cover)
      //   : this.lists[0].author.headimgurl;
      // 分享文案
      wxtool.share(share, this.$route.fullPath);
    },
    goGroup() {
      if (this.groupID > 0) {
        this.$router.replace({
          path: `/group/${this.groupID}`,
        });
      }
    },
    onDatePicker() {
      this.showDatePick = true;

      this.$nextTick(() => {
        // 打开之后选中当前默认值
        const { datePicker } = this.$refs;
        datePicker.setColumnValue(0, `${this.pickConfig.year}年`);
        const _month = this.ccolumns[1].values.find((i) => +i.val === +this.pickConfig.month);
        datePicker.setColumnValue(1, _month.text);
      });

      if (!this.pickYearWhiteMonth[this.pickConfig.year]) {
        this.getTimeRange(this.pickConfig.year);
      }

      // const _current = this.ccolumns[this.pickDefaultIndex];
      // if (_current.children.length === 1 && _current.val > 0) {
      //   this.getTimeRange(_current.val);
      // }
    },
    onPickerConfirm(val, index) {
      console.log(index);
      const year = val[0].val;
      const month = val[1].val;
      this.pickConfig = {
        year,
        month,
      };
      this.showDatePick = false;

      const _mText = month > 0 ? `${`${month}`.padStart(2, 0)}月` : '';
      this.pickText = year > 0 ? `${year}年 ${_mText}` : '全部';
      this.pageInit();
      this.requesting = true;
      this.finished = false;
      this.geData();
      this.onPickerCancel();
    },
    onPickerCancel() {
      this.showDatePick = false;
    },
    onPickerChange(picker, val, index) {
      console.log(index);
      const _changeYear = +val[0].val;

      if (_changeYear > 0 && !this.pickYearWhiteMonth[_changeYear]) {
        this.pickOptionsLoading = true;
        this.getTimeRange(_changeYear);
      } else if (_changeYear === 0) {
        this.generatePickerColumnsFillMonth(null, 0);
      } else {
        this.updateMonthColumn(this.pickYearWhiteMonth[_changeYear]);
      }
    },
    getTimeRange(year = 0) {
      const that = this;

      let url = '';
      if (that.renderType === 'user') {
        url = 'api/user/timeline/post/range';
      } else if (that.renderType === 'friend') {
        url = `api/user/friend/timeline-range/${this.friendUid}`;
      } else {
        url = `api/group/${this.groupID}/timeline/range`;
      }

      if (year > 0) {
        url += `?year=${year}`;
      }

      that.$request
        .get(url)
        .then((res) => {
          if (res.data) {
            if (year > 0) {
              this.generatePickerColumnsFillMonth(res.data, year);
            } else {
              this.generatePickerColumns(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    generatePickerColumnsFillMonth(range, year) {
      const months = year > 0 ? range.months : [0];
      this.pickYearWhiteMonth[year] = months;
      // 更新月份column
      this.updateMonthColumn(months);
      this.pickOptionsLoading = false;
    },
    updateMonthColumn(months) {
      const updateMonths = this.ccolumns[1].values.map((item) => {
        const t = item;
        const _d = !(
          months.includes(`${t.val}`.padStart(2, 0))
          || (t.val === 0 && !(months.length === 1 && months[0] === 0))
        );
        console.log(t.val, _d);
        return {
          ...t,
          disabled: _d,
        };
      });
      this.$set(this.ccolumns, 1, { values: [...updateMonths] });
    },
    generatePickerColumns(range) {
      const { years } = range;
      // 级联
      let _tmp = years.map((year) => ({
        text: `${year}年`,
        val: year,
      }));

      _tmp = [
        {
          text: '全部',
          val: 0,
        },
        ..._tmp,
      ];

      // this.columns = [...this.columns, ..._tmp];

      // const currentDefaultIndex = this.columns.findIndex((i) => +i.val === +this.pickConfig.year);
      // this.pickDefaultIndex = currentDefaultIndex;

      // 多列
      this.$set(this.ccolumns, 0, { values: [..._tmp] });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.date-picker) {
  .van-picker__confirm {
    color: #000;
  }

  .van-picker__cancel,
  .van-picker__confirm {
    font-size: 18px;
    font-weight: bold;
  }

  .van-picker-column__item {
    font-size: 18px;
    color: #c3c3c3;

    &.van-picker-column__item--selected {
      color: #000;
    }
  }
}
// .date-picker {
//   &::v-deep {
//     .van-picker__confirm {
//       color: #000;
//     }

//     .van-picker__cancel,
//     .van-picker__confirm {
//       font-size: 18px;
//       font-weight: bold;
//     }

//     .van-picker-column__item {
//       font-size: 18px;
//       color: #c3c3c3;

//       &.van-picker-column__item--selected {
//         color: #000;
//       }
//     }
//   }
// }
</style>
