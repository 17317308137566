<template>
  <div class="common-timeline-wrapper">
    <div class="timeline-list-container">
      <timeline-item
        v-for="item in lists"
        theme="timeline"
        :key="item.id"
        :info="item"
        :call-post-del="handlerPostDelete"
        :is-stranger="stranger"
        @postDel="onPostDel"
        @postReply="onPostReply"
        @click.native.stop="(e) => onJumpDetail(item.id, e)"
        v-bind="$attrs"
      ></timeline-item>
    </div>
    <!-- 回复 -->
    <reply-form
      @afterReply="onAfterReply"
      @cancel="onReplyFormCancel"
      :post-link="replyPostURI"
      :ex-info="exInfo"
      theme="timeline"
      :is-custom-tag="true"
    ></reply-form>
  </div>
</template>

<script>
import topicPost from '@/mixins/topic-post';
import { mapMutations, mapState } from 'vuex';
import timelineItem from './_item.vue';
import replyForm from '../common/_reply-form.vue';

export default {
  name: 'common-timeline-integrate',
  mixins: [topicPost],
  components: {
    timelineItem,
    replyForm,
  },
  props: ['wrapper-data', 'isStranger'],
  data() {
    return {
      requesting: false,
      exInfo: {},
    };
  },
  computed: {
    ...mapState(['friends']),
    replyPostURI() {
      return this.groupID
        ? `api/group/${this.groupID}/timeline/reply`
        : 'api/user/timeline/post/reply';
    },
    stranger() {
      return this.isStranger;
    },
  },
  watch: {
    wrapperData: {
      handler(val) {
        this.lists = val;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM']),
    onPostReply(data) {
      const that = this;
      const friendsUpdate = this.friends.map((item) => {
        const temp = item;
        temp.ex = data.relatedUsers.indexOf(item.info.uid) >= 0;
        return temp;
      });
      const info = { ...data, friendsUpdate };
      that.exInfo = info;
      that.OPEN_REPLY_FORM();
    },
    onAfterReply(res) {
      const that = this;
      const { reply } = res.data;
      const tempLists = that.lists;
      that.lists = tempLists.map((tl) => {
        const temp = tl;
        if (Number(temp.id) === Number(reply.post_id)) {
          temp.reply.push(reply);
        }
        return temp;
      });
      that.CLOSE_REPLY_FORM();
    },
    onReplyFormCancel() {
      this.CLOSE_REPLY_FORM();
    },
    onJumpDetail(id, e) {
      if (
        id
        && Number(this.$route.params.timelineid) !== id
        && e.target.tagName.toLowerCase() !== 'a'
      ) {
        const link = this.groupID > 0 ? `/group/${this.groupID}/timeline/${id}` : `/timeline/${id}`;
        this.$router.push({
          path: link,
        });
      }
    },
    // 处理帖子删除
    // handlerPostDelete() {
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve();
    //     }, 2000);
    //   });
    // },
    // onPostDel(postid) {
    //   console.log(`已经删除${postid}`);
    // },
  },
};
</script>

<style></style>
