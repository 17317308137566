var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"friend-timeline-list-container",class:{
    forbidden: _vm.forbidden,
    '-theme-group': _vm.renderType === 'group',
  }},[(
      (_vm.timelineid === undefined && _vm.$route.query.type !== 'all') || (_vm.groupID > 0 && _vm.timelineid > 0)
    )?_c('div',{staticClass:"ftl-top-info-container"},[_c('div',{staticClass:"info__container"},[(!(_vm.groupID > 0))?_c('div',{staticClass:"info__item"},[_vm._v("排行")]):_vm._e(),_c('div',{staticClass:"info__item"},[_vm._v(_vm._s(_vm.renderType === "group" ? "小组" : "亲友"))]),(!(_vm.groupID > 0 && _vm.timelineid > 0))?_c('div',{staticClass:"info__item"},[_vm._v(" "+_vm._s(_vm.renderType === "group" ? "时光" : "关联时光")+" ")]):_vm._e()]),_c('div',{staticClass:"info__container --val"},[(!(_vm.groupID > 0))?_c('div',{staticClass:"info__item"},[_vm._v(" "+_vm._s(_vm.$route.query.type ? "自己" : _vm.fpInfo("position"))+" ")]):_vm._e(),_c('div',{staticClass:"info__item",class:{
          blue: _vm.groupID > 0,
        },on:{"click":_vm.goGroup}},[_vm._v(" "+_vm._s(_vm.$route.query.type ? _vm.user ? _vm.user.nickname : "" : _vm.groupID > 0 ? _vm.groupInfo ? _vm.groupInfo.title : "" : _vm.fpInfo("nickname"))+" ")]),(!(_vm.groupID > 0 && _vm.timelineid > 0))?_c('div',{staticClass:"info__item"},[_c('strong',[_vm._v(_vm._s(_vm.totalSize))])]):_vm._e()])]):_vm._e(),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":""},on:{"load":_vm.geData},model:{value:(_vm.requesting),callback:function ($$v) {_vm.requesting=$$v},expression:"requesting"}},[_c('timeline-item',{staticClass:"friend-timeline-wrapper",attrs:{"wrapperData":_vm.lists,"is-stranger":_vm.isStranger,"renderType":_vm.renderType,"groupMangers":_vm.groupMangers}})],1),_c('slogan',{attrs:{"text":_vm.sloganText}}),(_vm.friendUid || _vm.groupID)?_c('div',{staticClass:"topic-create-bt",on:{"click":_vm.onCreateTimeline}},[_c('svg-icon',{attrs:{"icon-class":"icon-plus-red","color":_vm.renderType === 'group' ? '#027711' : '#C81D25'}})],1):_vm._e(),(_vm.pickerShow)?_c('div',{staticClass:"timeline-filter",on:{"click":_vm.onDatePicker}},[_c('div',{staticClass:"inner"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.pickText))]),_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":"icon-arrow-up","color":"#fff"}})],1)]):_vm._e(),_c('div',{ref:"xxx",staticClass:"bottom-menu-container"},[_c('share-bottom')],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDatePick),callback:function ($$v) {_vm.showDatePick=$$v},expression:"showDatePick"}},[_c('van-picker',{ref:"datePicker",staticClass:"date-picker",attrs:{"loading":_vm.pickOptionsLoading,"columns":_vm.ccolumns,"show-toolbar":""},on:{"change":_vm.onPickerChange,"confirm":_vm.onPickerConfirm,"cancel":_vm.onPickerCancel}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }